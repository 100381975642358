
import { Options, Vue } from 'vue-class-component'
import { MessageInput, PreferredContactMethod } from '@/MessageInput'

export default class ContactSummary extends Vue {
  msg: MessageInput = new MessageInput()
  toastHeader = ''
  toastMsg = ''
  isSucessful = false
  submitMessageUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5249/ForwardMessage' : 'https://public-backend.bengreenslandscaping.com/ForwardMessage'
  // submitMessageUrl = 'https://public-backend.bengreenslandscaping.com/ForwardMessage'
  phoneIsInvalid = false;

  mounted (): void {
    this.resetForm()
  }

  resetForm (): void {
    this.msg.firstName = ''
    this.msg.lastName = ''
    this.msg.callBackNumber = ''
    this.msg.email = ''
    this.msg.preferredContactMethod = PreferredContactMethod.Phone
    this.msg.subject = 'Quote Request'
    this.msg.message = ''
  }

  async submitForm (): Promise<void> {
    this.validateForm()

    if (this.phoneIsInvalid) {
      return
    }
    const response = await fetch(this.submitMessageUrl, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.msg)
    })
    if (response.ok) {
      this.showToast('Success', 'Message sent successfully!', true)
      this.resetForm()
    } else {
      this.showToast('Failed', 'Failed to send message...', false)
    }
  }

  validateForm (): void {
    this.phoneIsInvalid = this.msg.callBackNumber.trim().length < 7
  }

  showToast (header: string, msg: string, isSucessful: boolean): void {
    console.log('huh111')
    const ele = this.$refs.toast as Element
    ele.classList.remove('hide')
    ele.classList.add('show')
    this.toastHeader = header
    this.toastMsg = msg
    this.isSucessful = isSucessful
  }
}
