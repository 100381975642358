export enum PreferredContactMethod {
  None = 0,
  Phone = 1,
  Email = 2
}

export class MessageInput {
  public firstName!: string
  public lastName!: string
  public email!: string
  public subject!: string
  public callBackNumber!: string
  public preferredContactMethod!: PreferredContactMethod
  public message!: string
}
