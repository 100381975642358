
import { Options, Vue } from 'vue-class-component'
import MainHeader from './components/MainHeader.vue'
import Banner from './components/Banner.vue'
import Services from './components/Services.vue'
import ContactSummary from './components/ContactSummary.vue'

@Options({
  components: {
    MainHeader,
    Banner,
    Services,
    ContactSummary
  }
})
export default class App extends Vue {}
