import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container body-color main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_Services = _resolveComponent("Services")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_ContactSummary = _resolveComponent("ContactSummary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainHeader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Services),
      _createVNode(_component_Banner),
      _createVNode(_component_ContactSummary)
    ])
  ], 64))
}