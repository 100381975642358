
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String
  }
})
export default class Banner extends Vue {
  msg!: string;
  instaScriptLoaded = false;

  async mounted (): Promise<void> {
    await this.setup()
  }

  async setup (): Promise<void> {
    const script = document.createElement('script')
    script.setAttribute('src', '//www.instagram.com/embed.js')
    script.async = true
    script.onload = () => {
      this.instaScriptLoaded = true
    }

    document.head.appendChild(script)
  }
}
